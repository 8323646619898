
.container{
  width: 100%;
  display:flex;
  flex-direction: column;
  align-items: center;
  background: #282c34;
  color: white;
  .header-top{
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    //background: url('../images/eyes1.jpg') no-repeat center center;
    background-size: 100% 100%;
    //opacity: 20%;
    text{
      opacity:100% !important;
      color: white;

      font-size: 40px;
    }
  }
  .header-bottom{
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-evenly;
    background: #707078;
    font-size: 20px;
    color: white;
    align-items: center;
  }
}
