.price-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .price-row{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-bottom: 15px;

    .price-row-title{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .price-row-content{
      width: 90%;
      display: flex;
      justify-content: space-evenly;
      font-size: 15px;
      .price-row-content-left{
        //background: green;
        width: 75%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
      .price-row-content-right{
        width: 20%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}