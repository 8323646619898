.tio{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;


  .tio-row{
    width: 30%;
    min-width: 300px;
    height: 100%;

    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    //background: #878383;
    .tio-title{
      display: flex;
      justify-content: center;
      width: 90%;
      height: 20px;
      font-weight: bold;
      font-size: 20px;
    }
    .tio-content{
      display: flex;
      justify-content: center;
      width: 90%;
      margin-top: 10px;
      height: 100%;
      font-size: 17px;
      margin-bottom: 10px;
    }
  }
}