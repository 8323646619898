.service-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;


  .service-title{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    margin-top: 30px;
  }
  .service-row{
    width: 90%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    //margin-top: 10px;

    .service-left{
      width: 350px;
      height:350px;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width:350px;
        height:250px;
      }

    }
    .service-right{
      width: 550px;
      height:350px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .service-right-title{
        display: flex;
        justify-content: center;
        width: 90%;
        font-size: 25px;
        font-weight: bold;
      }
      .service-right-content{
        width: 90%;
        font-size: 20px;
      }
    }
  }
}