@media only screen and (max-width: 800px) {
  .hide-element {
    display: none;
  }
  .home-phone-img{

  }
}

.home-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;


  .home-top{
    margin-top: 20px;
    width: 85%;
    min-width: 390px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    justify-content: space-evenly;
    .home-top-left{
      width: 50%;
      min-width: 400px;
      height: 100%;
      img{
        width: 100%;
        height: 500px;
      }
    }
    .home-top-right{
      width: 50%;
      min-width: 390px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex-wrap: nowrap;
      .title{
        display: flex;
        justify-content: flex-start;
        margin-left: 30px;
        width: 80%;
        font-size: 30px;
        font-weight: bold;
      }
      .content{
        width: 95%;
        font-size: 15px;
        margin-left: 30px;

      }
    }
  }
  .home-mid1{
    margin-top: 10px;
    margin-bottom: 20px;
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .home-mid-title{
      display: flex;
      justify-content: flex-start;
      margin-top: 30px;
      margin-bottom: 10px;
      width: 100%;
      font-size: 30px;
      font-weight: bold;
    }
  }
  .home-mid{
    margin-top: 10px;
    margin-bottom: 15px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    img{
      width: 250px;
      height: 250px;
      //max-width: 300px;
      //max-height: 300px;
    }
  }
  .home-bottom{
    width: 100%;
    height: 800px;
    .home-bottom-block{
      width: 90%;
      height: 400px;
    }
    .home-bottom-address{

    }
    .home-bottom-img{

    }
  }
}