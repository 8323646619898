.footer-container{
  height: 150px;
  background: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  .footer-content{
    //padding-top: 30px;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text{

    }
  }
}