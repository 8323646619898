.about-container{
  width: 100%;
  height:100%;
  min-height: 800px;
  background: #282c34;
  color: white;
  display: flex;
  justify-content:center;
  align-items: center;
  flex-wrap: wrap;
  .about-left{
    background: black;
    width: 45%;
    min-width: 350px;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    .title{
      font-size: 50px;
      margin-bottom: 20px;
    }
    text{
      margin-bottom: 10px;
    }
  }
  .about-right{
    width: 45%;
    min-width: 350px;
    height: 600px;
    img{
      height: 100%;
      width: 100%;
    }
  }
}